<script setup lang="ts">
const { data: user } = useUser();
</script>

<template>
  <PrimeChip
    v-if="user?.zmidAssoc?.is_active"
    class="!bg-successColor h-5 text-[10px] font-semibold text-surface-section"
  >
    Aktywne
  </PrimeChip>

  <PrimeChip
    v-else-if="user"
    class="h-5 !bg-errorColor text-[10px] font-semibold text-surface-section"
  >
    Nieaktywne
  </PrimeChip>
</template>
